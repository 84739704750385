<template>
  <div class="upload">
    <div>
      <div v-if="info.orderInfo.tprocAudit.approveStatus == '0' || info.orderInfo.tprocAudit.approveStatus == '3'">
        <div class="upload-content">
          <div class="upload-up" style="margin-right:10px">
            <span>上传发票：</span>
            <el-upload
              class="up-btn"
              :show-file-list="false"
              accept=".JPG,.PNG,.PDF,.ZIP"
              :action="uploadPath"
              :before-upload="beforeUpload"
              :on-error="uploadError"
              :on-success="handleSuccess"
              multiple
            >
              <el-button type="primary">选择文件</el-button>
            </el-upload>
          </div>
          <div class="upload-up">
            <span>上传非发票：</span>
            <el-upload
              class="up-btn"
              :show-file-list="false"
              accept=".JPG,.PNG,.PDF"
              :action="uploadPath"
              :before-upload="otherBeforeUpload"
              :on-error="otherUploadError"
              :on-success="otherSuccess"
              multiple
            >
              <el-button type="primary">选择文件</el-button>
            </el-upload>
          </div>
          <el-tooltip placement="top">
            <div slot="content">
              <img style="height:450px" src="@/assets/image/bill.jpg" alt="" />
            </div>
            <p class="title">样例预览</p>
          </el-tooltip>
        </div>
        <p style="text-align: center;margin: 20px 0;">温馨提示：可上传JPG,PNG,PDF格式（最大能上传10M）、ZIP压缩文件（最大能上传10M）</p>
      </div>
      <table v-if="tableData.length && info.orderInfo.tprocAudit.approveStatus != '2'" class="upload-table">
        <tr>
          <th width="60px">系统校验</th>
          <th width="110px">发票代码</th>
          <th width="80px">发票号码</th>
          <th>含税金额（元）</th>
          <th>不含税金额（元）</th>
          <th width="70px">发票类型</th>
          <th>开票时间</th>
          <th>操作</th>
        </tr>
        <tr v-for="(item, index) in tableData" :key="index">
          <td>
            <img v-if="(item.purchaserName !== item.buyClientName || item.sellerName !== item.sellClientName) && item.invoiceNo" src="@/assets/image/false.png" />
            <img v-else src="@/assets/image/true.png" />
          </td>
          <td>
            <span>{{ item.invoiceSn || '-' }}</span>
          </td>
          <td>
            <span>{{ item.invoiceNo || '-' }}</span>
          </td>
          <td>
            <span>{{ item.invoiceAmt | formatPrice }}</span>
          </td>
          <td>
            <span>{{ item.notInvoiceAmt | formatPrice }}</span>
          </td>
          <td>
            <span>{{ item.invoiceType == '0' ? '专用发票' : item.invoiceType == '1' ? '普通发票' : '额外文件' }}</span>
          </td>
          <td>
            {{ getFormatDate(item.invoiceTime) }}
          </td>
          <td>
            <p>
              <span style="color:#4ba5ff;cursor: pointer;" @click="showDialogInvoiceComparison(item)">查看发票</span>
              <span v-if="info.orderInfo.tprocAudit.approveStatus != '1'" style="color:#4ba5ff;cursor: pointer;" @click="delItem(index, item)">删除</span>
            </p>
          </td>
        </tr>
      </table>

      <div v-if="tableData.length && info.orderInfo.tprocAudit.approveStatus != '2'" class="upload-total">
        <span>发票总数：{{ invoiceData.length }}</span>
        <span>含税金额（元）：{{ getAllinvoiceAmtString | formatPrice }}</span>
        <span>不含税金额（元）：{{ getAllnotInvoiceAmtString | formatPrice }}</span>
        <span>合同总金额：{{ info.orderInfo.orderPrice | formatPrice }}</span>
      </div>
    </div>
    <dialog-invoice-comparison @getTableData="getTableData" :goodsPrice="info.orderInfo.orderPrice | formatPrice" ref="invoiceComparison" />
  </div>
</template>
<script>
import {formatDialogFile} from '@/modules/mixin/order-mixin.js';
import DialogInvoiceComparison from '@/modules/User/components/order/operate/dialog-Invoice-comparison.vue';
import {Loading} from 'element-ui';
import {uploadPath, vatInvoiceDistinguish, getKPEchoInformation} from '@/modules/User/api.js';
export default {
  data() {
    return {
      myLoading: '',
      fileUrls: [],
      tableFileUrls: [],
      tableFileNames: [],
      fileNum: 0,
      flag: false,
      uploadPath,
      imageName: '',
      fileList: [],
      tableData: [],
      // 发票数据
      invoiceData: [],
      // 非发票数据
      noInvoiceData: [],
      allinvoiceAmtString: ''
    };
  },
  mixins: [formatDialogFile],
  props: {
    info: {
      type: Object
    },
    KHData: {}
  },
  components: {
    DialogInvoiceComparison
  },
  watch: {
    'info.orderInfo.tprocAudit.approveStatus': function(val) {
      if (val == '3') this.resetData();
    },
    KHData: {
      deep: true,
      handler: function(val) {
        this.tableData = val;
        this.invoiceData = val.filter(item => !item.invoiceNo);
      }
    }
  },
  computed: {
    // 不含税总额
    getAllnotInvoiceAmtString() {
      let myNum = 0;
      this.tableData.forEach(item => {
        if (item.notInvoiceAmt) myNum += item.notInvoiceAmt;
      });
      if (myNum.toString().indexOf('.') !== -1) myNum = myNum.toFixed(2);
      return myNum;
    },

    // 含税总额
    getAllinvoiceAmtString() {
      let myNum = 0;
      this.tableData.forEach(item => {
        if (item.invoiceAmt) myNum += item.invoiceAmt;
      });
      if (myNum.toString().indexOf('.') !== -1) myNum = myNum.toFixed(2);
      return myNum;
    }
  },
  methods: {
    resetData() {
      this.tableFileUrls = [];
      this.tableFileNames = [];
      this.invoiceData = [];
      this.noInvoiceData = [];
      this.tableData = [];
    },
    // 获取回显数据
    async getfillingData() {
      if (this.info.orderInfo.orderProcess == 'KH' && this.info.orderInfo.orderStatus == 'DFH') return;
      let params = {
        orderId: this.info.orderInfo.id,
        record: this.info.orderInfo.currentTerm,
        status: 'KP',
        approveStatus: this.info.orderInfo.tprocAudit.approveStatus
      };
      let res = await getKPEchoInformation(params);
      if (res.errno === 0) {
        this.tableData = res.data.invoiceInfoList;
      }
    },
    // 非发票文件上传的时候
    otherSuccess(res, data) {
      let myObj = {
        fileUrl: data.response.data.url,
        fileName: data.name,
        invoiceType: '2'
      };

      this.fileUrls.push(myObj);
      if (this.fileUrls.length == this.fileNum) {
        this.noInvoiceData = this.noInvoiceData.concat(this.fileUrls);
        this.tableData = this.invoiceData.concat(this.noInvoiceData);
        this.fileUrls = [];
        this.myLoading.close();
        this.fileNum = 0;
      } else {
        this.fileUrls = [];
        this.myLoading.close();
        this.fileNum = 0;
      }
    },
    // 非发票文件上传之前
    otherBeforeUpload() {
      this.fileNum++;
      this.myLoading = Loading.service();
    },
    // 非发票文件上传失败
    otherUploadError() {
      this.fileNum--;
      this.myLoading.close();
    },
    // 在父组件调用这个提交方法
    emitInvoicingData() {
      let mynumber = 0;
      if (!this.tableData.length) {
        this.$message.warning('请上传发票');
        return;
      }
      // 判断准备上传的发票买家和卖家信息是否一致
      this.tableData.forEach(item => {
        if (item.purchaserName !== item.buyClientName || item.sellerName !== item.sellClientName) mynumber++;
      });
      if (mynumber) {
        this.$message.warning('系统识别到买家或卖家与订单交易信息不一致，请先修改错误的发票信息后，重新上传该发票');
        return;
      }
      // 如果发票金额和订单金额不一致
      if (this.getAllinvoiceAmtString != this.info.orderInfo.orderPrice && !this.flag) {
        this.$confirm('系统识别到发票总金额与订单合同金额不一致，请先核实上传的发票信息确认无误后，再进行提交', {
          confirmButtonText: '强制提交',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.tableFileUrls = this.tableData.map(item => {
            return item.fileUrl;
          });
          this.tableFileNames = this.tableData.map(item => {
            return item.fileName;
          });
          this.$emit('getInvoicingData', {
            data: this.tableData,
            flag: true,
            isSame: this.getAllinvoiceAmtString == this.info.orderInfo.orderPrice,
            tableFileUrls: this.tableFileUrls,
            tableFileNames: this.tableFileNames
          });
        });
      }
      // 如果发票金额和订单总额一样，执行下面的方法(添加flag的原因是父组件会重复调用这个方法，这个flag相当于简易的节流阀，写这个判断是因为上面的方法是弹出框，js执行会变成异步)
      if (this.getAllinvoiceAmtString == this.info.orderInfo.orderPrice && !this.flag) {
        this.tableFileUrls = this.tableData.map(item => {
          return item.fileUrl;
        });
        this.tableFileNames = this.tableData.map(item => {
          return item.fileName;
        });
        this.$emit('getInvoicingData', {
          data: this.tableData,
          flag: true,
          isSame: this.getAllinvoiceAmtString == this.info.orderInfo.orderPrice,
          tableFileUrls: this.tableFileUrls,
          tableFileNames: this.tableFileNames
        });
      }
    },
    // 显示信息确认弹框
    showDialogInvoiceComparison(data) {
      this.$refs.invoiceComparison.dialogVisible = true;
      this.$refs.invoiceComparison.IdentifyData = data;
    },
    getFormatDate(val) {
      if (!val) return '-';
      return this.$moment(val).format('YYYY年MM月DD日');
    },
    // 删除某一行信息
    delItem(index, item) {
      console.log(item);
      this.tableData[index] = '';
      this.tableData = this.tableData.filter(Boolean);
      // 判断删除的文件是发票还是非发票，然后到对应的数组中删除
      if (item.invoiceNo) {
        this.invoiceData = this.invoiceData.filter(data => item.invoiceNo != data.invoiceNo);
      } else {
        this.noInvoiceData = this.noInvoiceData.filter(data => item.fileName != data.fileName);
      }
    },
    beforeUpload() {
      this.myLoading = Loading.service();
      this.fileNum++;
    },
    uploadError() {
      this.fileNum--;
      this.myLoading.close();
    },
    getTableData(data) {
      this.tableData.forEach(item => {
        if (item.invoiceNo == data.invoiceNo) {
          item = data;
        }
      });
    },
    async handleSuccess(response) {
      let parmas = {};
      this.fileUrls.push(response.data.url);
      if (this.fileUrls.length == this.fileNum) {
        parmas.isBase64 = true;
        parmas.orderId = this.info.orderInfo.id;
        parmas.fileUrls = this.fileUrls.toString();
        let res = await vatInvoiceDistinguish(parmas);
        if (res.errno == 0) {
          this.myLoading.close();
          // 变量初始化
          this.fileUrls = [];
          this.fileNum = 0;
          this.invoiceData = this.$L.uniqBy(this.invoiceData.concat(res.data.tInvoiceInfo), 'invoiceNo');
          this.tableData = this.invoiceData.concat(this.noInvoiceData);
        } else {
          this.myLoading.close();
          this.fileUrls = [];
          this.fileNum = 0;
        }
      }
    }
  }
};
</script>
<style lang="less" scoped>
.upload {
  .title {
    margin-left: 30px;
    color: #66b1ff;
    cursor: pointer;
  }
  &-content {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &-up {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    span::before {
      content: '*';
      color: red;
    }

    .up-btn /deep/ .el-button--primary {
      width: 101px;
      height: 34px;
      line-height: 10px;
    }
  }
  &-table {
    margin-top: 44px;
    width: 100%;
    table-layout: fixed;
    border-collapse: collapse;
    img {
      width: 15px;
      height: 15px;
    }
    p {
      padding: 0 10px;
      display: flex;
      justify-content: space-around;
    }
    th,
    td {
      text-align: center;
      height: 27px;
      padding: 0 5px;
      border: 1px solid #bbb;
    }
    input {
      padding: 0;
      border: none;
      width: 100%;
      height: 100%;
    }
  }
  &-total {
    background-color: #f2f2f2;
    height: 28px;
    line-height: 28px;
    text-align: center;
    border: 1px solid #bbb;
    border-top: none;
    span {
      margin-right: 20px;
    }
  }
  .btn {
    & /deep/ .el-button--primary {
      width: 130px;
    }
    text-align: center;
  }
  &-line {
    margin: 50px 0;
    border-top: 1px dashed #bbb;
  }
}
</style>
