var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "pay" },
    [
      _c(
        "div",
        [
          !_vm.isDisabled
            ? _c("div", [
                _c(
                  "div",
                  { staticClass: "pay-up" },
                  [
                    _c("span", [_vm._v("上传支付凭证：")]),
                    _c(
                      "el-upload",
                      {
                        staticClass: "up-btn",
                        attrs: {
                          accept: ".JPG,.PNG,.PDF",
                          action: _vm.uploadPath,
                          "on-remove": _vm.uploadremove,
                          "on-success": _vm.handleSuccess,
                          multiple: "",
                        },
                      },
                      [
                        _c("el-button", { attrs: { type: "primary" } }, [
                          _vm._v("选择文件"),
                        ]),
                      ],
                      1
                    ),
                    _vm.getPaymode
                      ? _c("el-tooltip", { attrs: { placement: "top" } }, [
                          _c(
                            "div",
                            { attrs: { slot: "content" }, slot: "content" },
                            [
                              _c("img", {
                                staticStyle: { height: "450px" },
                                attrs: {
                                  src: require("@/modules/User/image/huipiao.png"),
                                  alt: "",
                                },
                              }),
                            ]
                          ),
                          _c("p", { staticClass: "upload-title" }, [
                            _vm._v("样例预览"),
                          ]),
                        ])
                      : _vm._e(),
                  ],
                  1
                ),
                _vm.getPaymode
                  ? _c(
                      "p",
                      {
                        staticStyle: {
                          "text-align": "center",
                          margin: "20px 0",
                        },
                      },
                      [_vm._v("可上传格式：JPG，PNG，PDF上传后自动识别显示")]
                    )
                  : _c(
                      "p",
                      {
                        staticStyle: {
                          "text-align": "center",
                          margin: "20px 0",
                        },
                      },
                      [_vm._v("可上传格式：JPG，PNG，PDF格式")]
                    ),
              ])
            : _vm._e(),
          _vm.isDisabled
            ? _c(
                "div",
                { staticClass: "pay-filling" },
                [
                  _c("span", [_vm._v("票据凭证：")]),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.showDialog },
                    },
                    [_vm._v("查看文件")]
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.getPaymode
            ? _c(
                "el-form",
                {
                  ref: "payForm",
                  staticClass: "pay-form",
                  attrs: {
                    model: _vm.noteInfo,
                    rules: _vm.rules,
                    inline: true,
                  },
                },
                [
                  _c("el-row", [
                    _c("div", { staticClass: "pay-line" }, [
                      _c("p", [_vm._v("收/付款账户信息")]),
                      _c("span"),
                    ]),
                  ]),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 11 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              staticClass: "form-item",
                              attrs: {
                                label: "付款人名称：",
                                prop: "payerName",
                              },
                            },
                            [
                              _c("el-input", {
                                staticClass: "input-width",
                                attrs: {
                                  disabled: _vm.isDisabled,
                                  size: "mini",
                                },
                                model: {
                                  value: _vm.noteInfo.payerName,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.noteInfo, "payerName", $$v)
                                  },
                                  expression: "noteInfo.payerName",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 11 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              staticClass: "form-item",
                              attrs: {
                                label: "收款人名称：",
                                prop: "payeeName",
                              },
                            },
                            [
                              _c("el-input", {
                                staticClass: "input-width",
                                attrs: {
                                  disabled: _vm.isDisabled,
                                  size: "mini",
                                },
                                model: {
                                  value: _vm.noteInfo.payeeName,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.noteInfo, "payeeName", $$v)
                                  },
                                  expression: "noteInfo.payeeName",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 11 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "付款人账号：",
                                prop: "payerAccount",
                              },
                            },
                            [
                              _c("el-input", {
                                staticClass: "input-width",
                                attrs: {
                                  disabled: _vm.isDisabled,
                                  size: "mini",
                                },
                                model: {
                                  value: _vm.noteInfo.payerAccount,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.noteInfo, "payerAccount", $$v)
                                  },
                                  expression: "noteInfo.payerAccount",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 11 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "收款人账号：",
                                prop: "payeeAccount",
                              },
                            },
                            [
                              _c("el-input", {
                                staticClass: "input-width",
                                attrs: {
                                  disabled: _vm.isDisabled,
                                  size: "mini",
                                },
                                model: {
                                  value: _vm.noteInfo.payeeAccount,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.noteInfo, "payeeAccount", $$v)
                                  },
                                  expression: "noteInfo.payeeAccount",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 11 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "付款人开户银行：",
                                prop: "payerBankName",
                              },
                            },
                            [
                              _c("el-input", {
                                staticClass: "input-width",
                                attrs: {
                                  disabled: _vm.isDisabled,
                                  size: "mini",
                                },
                                model: {
                                  value: _vm.noteInfo.payerBankName,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.noteInfo, "payerBankName", $$v)
                                  },
                                  expression: "noteInfo.payerBankName",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 11 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "收款人开户银行：",
                                prop: "payeeBankName",
                              },
                            },
                            [
                              _c("el-input", {
                                staticClass: "input-width",
                                attrs: {
                                  disabled: _vm.isDisabled,
                                  size: "mini",
                                },
                                model: {
                                  value: _vm.noteInfo.payeeBankName,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.noteInfo, "payeeBankName", $$v)
                                  },
                                  expression: "noteInfo.payeeBankName",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("el-row", [
                    _c("div", { staticClass: "pay-line" }, [
                      _c("p", { staticStyle: { width: "65px" } }, [
                        _vm._v("汇票信息"),
                      ]),
                      _c("span"),
                    ]),
                  ]),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 11 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "票据金额(元)：",
                                prop: "notePrice",
                              },
                            },
                            [
                              _c("el-input", {
                                staticClass: "input-width",
                                attrs: {
                                  disabled: _vm.isDisabled,
                                  size: "mini",
                                },
                                nativeOn: {
                                  "!copy": function ($event) {
                                    $event.preventDefault()
                                  },
                                  "!cut": function ($event) {
                                    $event.preventDefault()
                                  },
                                },
                                model: {
                                  value: _vm.noteInfo.notePrice,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.noteInfo, "notePrice", $$v)
                                  },
                                  expression: "noteInfo.notePrice",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 11 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "票据号码：",
                                prop: "noteNumber",
                              },
                            },
                            [
                              _c("el-input", {
                                staticClass: "input-width",
                                attrs: {
                                  disabled: _vm.isDisabled,
                                  size: "mini",
                                },
                                nativeOn: {
                                  "!copy": function ($event) {
                                    $event.preventDefault()
                                  },
                                  "!cut": function ($event) {
                                    $event.preventDefault()
                                  },
                                },
                                model: {
                                  value: _vm.noteInfo.noteNumber,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.noteInfo, "noteNumber", $$v)
                                  },
                                  expression: "noteInfo.noteNumber",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  !_vm.isDisabled
                    ? _c("el-row", [
                        _c("div", { staticClass: "pay-line" }, [
                          _c("p", { staticStyle: { width: "95px" } }, [
                            _vm._v("确认汇票信息"),
                          ]),
                          _c("span"),
                        ]),
                      ])
                    : _vm._e(),
                  !_vm.isDisabled
                    ? _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 11 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "票据金额确认(元)：",
                                    prop: "notePriceConfirm",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    staticClass: "input-width",
                                    attrs: { size: "mini" },
                                    model: {
                                      value: _vm.noteInfo.notePriceConfirm,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.noteInfo,
                                          "notePriceConfirm",
                                          $$v
                                        )
                                      },
                                      expression: "noteInfo.notePriceConfirm",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 11 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "票据号码确认：",
                                    prop: "noteNumberConfirm",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    staticClass: "input-width",
                                    attrs: { size: "mini" },
                                    model: {
                                      value: _vm.noteInfo.noteNumberConfirm,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.noteInfo,
                                          "noteNumberConfirm",
                                          $$v
                                        )
                                      },
                                      expression: "noteInfo.noteNumberConfirm",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c("dialog-browse-documents", { ref: "documents" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }