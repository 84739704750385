<template>
  <div style="padding: 50px 50px 25px;background-color: #fff;">
    <div class="ignFor" v-if="info.orderInfo.tprocAudit.approveStatus == '0' || info.orderInfo.tprocAudit.approveStatus == '3'">
      <div class="ignFor-up">
        <div class="flex" style="align-items: center;">
          <span class="up-file">上传签收凭证：</span>
          <el-upload class="up-btn" :action="uploadPath" :on-success="success" :on-remove="remove" accept=".JPG,.PNG,.PDF,.ZIP" multiple>
            <el-button type="primary">选择文件</el-button>
          </el-upload>
        </div>
        <p style="text-align: center;margin: 20px 0;">温馨提示：可上传JPG,PNG,PDF格式（最大能上传10M）、ZIP压缩文件（最大能上传10M）</p>
      </div>
    </div>
    <div class="filling" v-else>
      <img src="@/assets/order/success.png" />
      <p style="margin-top:15px">买家已提交</p>
      <p style="margin-bottom:15px">待审核</p>
      <div>
        <span>签收凭证：&nbsp;</span>
        <el-button type="primary" @click="$refs.documents.dialogVisible = true">查看文件</el-button>
      </div>
    </div>
    <!-- 查看文件弹窗 -->
    <dialog-browse-documents ref="documents" />
  </div>
</template>
<script>
import {formatDialogFile} from '@/modules/mixin/order-mixin.js';
import {uploadPath, getQSEchoInformation} from '@/modules/User/api.js';
import dialogBrowseDocuments from '@/modules/User/components/order/operate/dialog-browse-documents';
export default {
  props: {
    info: {
      type: Object
    }
  },
  mixins: [formatDialogFile],
  data() {
    return {
      uploadPath,
      fileUrls: [],
      fileNames: []
    };
  },
  components: {
    dialogBrowseDocuments
  },
  methods: {
    resetData() {
      this.fileUrls = [];
      this.fileNames = [];
    },
    // 获取回显数据
    async getfillingData() {
      let params = {
        orderId: this.$route.query.orderid,
        status: 'SH',
        record: this.info.orderInfo.currentTerm,
        approveStatus: this.info.orderInfo.tprocAudit.approveStatus
      };
      let res = await getQSEchoInformation(params);
      if (res.errno === 0) {
        this.$refs.documents.imgData = this.formatFile(res.data).arr;
        this.$refs.documents.total = this.formatFile(res.data).total;
      }
    },
    success(response, file) {
      this.fileUrls.push(response.data.url);
      this.fileNames.push(file.name);
    },
    remove(file) {
      this.fileUrls = this.fileUrls.filter(item => {
        return item !== file.response.data.url;
      });
      this.fileNames = this.fileNames.filter(item => {
        return item !== file.name;
      });
    },
    submitForm() {
      if (this.fileUrls.length) {
        this.$emit('getIgnForData', {fileUrls: this.fileUrls, fileNames: this.fileNames, flag: true});
      } else {
        this.$message.warning('请上传签收凭证');
      }
    }
  }
};
</script>
<style lang="less" scoped>
.ignFor {
  display: flex;
  justify-content: center;
}
.ignFor-up {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  .up-file {
    position: relative;
  }
  .up-file::before {
    content: '*';
    color: red;
    position: absolute;
    top: 0;
    left: -10px;
  }
}
.filling {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  p {
    font-size: 18px;
    color: #6ab3ff;
  }
}
</style>
