<template>
  <div class="myship">
    <!-- 上传部分 -->
    <div v-if="info.orderInfo.tprocAudit.approveStatus == '0' || info.orderInfo.tprocAudit.approveStatus == '3'">
      <div class="myship-send">
        <span class="icon">上传发货凭证：</span>
        <el-upload class="up-btn" :action="uploadPath" :on-success="success" :on-remove="remove" accept=".JPG,.PNG,.PDF,.ZIP" multiple>
          <el-button type="primary">选择文件</el-button>
        </el-upload>
      </div>
      <p style="text-align: center;margin: 20px 0;">温馨提示：可上传JPG,PNG,PDF格式（最大能上传10M）、ZIP压缩文件（最大能上传10M）</p>
      <el-form :model="ruleForm" status-icon :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
        <el-row type="flex" justify="space-between" class="row-bg">
          <el-col>
            <el-form-item label="物流公司" prop="shipChannel">
              <el-input v-model="ruleForm.shipChannel"></el-input>
            </el-form-item>
          </el-col>
          <el-col>
            <el-form-item label="物流号" prop="shipSn">
              <el-input v-model="ruleForm.shipSn"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <!-- 回显结果页部分 -->
    <div v-else class="myship-main">
      <el-form :model="ruleForm" status-icon :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
        <el-row :gutter="24" type="flex" class="row-bg">
          <el-col :span="16">
            <el-form-item label="物流公司" prop="shipChannel">
              <el-input disabled v-model="fillingShipChannel"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="16">
            <el-form-item label="物流号" prop="shipSn">
              <el-input disabled v-model="fillingShipSn"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8" style="text-align: right;">
            <span>发货凭证：</span>
            <el-button type="primary" @click="$refs.documents.dialogVisible = true">查看文件</el-button>
          </el-col>
        </el-row>
      </el-form>
      <!-- 发票回显部分 -->
    </div>
    <!-- 查看文件弹窗 -->
    <dialog-browse-documents ref="documents" />
    <!-- 上传发票部分 -->
    <Invoicing ref="Invoicing" v-if="info.orderInfo.orderProcess == 'KH' && info.orderInfo.orderStatus == 'DFH'" @getInvoicingData="getInvoicingData" :KHData="KHData" :info="info" />
  </div>
</template>
<script>
import {formatDialogFile} from '@/modules/mixin/order-mixin.js';
import {uploadPath, getFHEchoInformation} from '@/modules/User/api.js';
import Invoicing from '@/modules/User/components/order/operate/invoicing.vue';
import dialogBrowseDocuments from '@/modules/User/components/order/operate/dialog-browse-documents';
export default {
  props: {
    info: {
      type: Object
    }
  },
  components: {
    dialogBrowseDocuments,
    Invoicing
  },
  mixins: [formatDialogFile],
  data() {
    return {
      uploadPath,
      fileUrls: [],
      fileNames: [],
      // 回显的物流公司数据
      fillingShipChannel: '',
      // 回显的物流号数据
      fillingShipSn: '',
      ruleForm: {
        shipChannel: '',
        shipSn: ''
      },
      // 发票相关信息
      invoicingData: {},
      KHData: {},
      invoicingFlag: false,
      rules: {
        shipChannel: [{required: true, message: '请输入物流公司', trigger: 'blur'}],
        shipSn: [{required: true, message: '请输入物流号', trigger: 'blur'}]
      }
    };
  },
  methods: {
    getInvoicingData(data) {
      this.invoicingData = data;
      this.invoicingFlag = data.flag;
      this.$refs.Invoicing.flag = data.flag;
      // 添加这个判断是为了防止发票总额相等的情况下两次调用提交接口
      if (!data.isSame) {
        this.submitForm();
        this.$emit('submitOpr');
      }
    },
    resetData() {
      this.ruleForm = {
        shipChannel: '',
        shipSn: ''
      };
      this.fileUrls = [];
      this.fileNames = [];
    },
    async getfillingData() {
      if (this.info.orderInfo.tprocAudit.approveStatus == '1' || this.info.orderInfo.tprocAudit.approveStatus == '3') {
        let params = {
          orderId: this.$route.query.orderid,
          status: 'FH',
          record: this.info.orderInfo.currentTerm,
          approveStatus: this.info.orderInfo.tprocAudit.approveStatus
        };
        let res = await getFHEchoInformation(params);
        if (res.errno === 0) {
          this.fillingShipChannel = res.data.shipInfo.shipChannel;
          this.fillingShipSn = res.data.shipInfo.shipSn;
          this.KHData = res.data.invoiceInfoList;
          this.$refs.documents.imgData = this.formatFile(res.data.torderRecord).arr;
          this.$refs.documents.total = this.formatFile(res.data.torderRecord).total;
        }
      }
    },
    success(response, file) {
      this.fileUrls.push(response.data.url);
      this.fileNames.push(file.name);
      this.$message.success('上传成功');
    },
    remove(file) {
      this.fileUrls = this.fileUrls.filter(item => {
        return item !== file.response.data.url;
      });
      this.fileNames = this.fileNames.filter(item => {
        return item !== file.name;
      });
    },

    submitForm() {
      if (this.info.orderInfo.orderProcess == 'KH' && this.info.orderInfo.orderStatus == 'DFH') {
        this.$refs.Invoicing.emitInvoicingData();
        if (!this.invoicingFlag) return;
      }
      this.$refs.ruleForm.validate(valid => {
        this.$emit('shipSuccess', {
          invoicingData: this.invoicingData,
          flag: valid,
          fileUrls: this.fileUrls,
          fileNames: this.fileNames,
          shipChannel: this.ruleForm.shipChannel,
          shipSn: this.ruleForm.shipSn
        });
      });
    }
  }
};
</script>
<style lang="less" scoped>
.myship {
  padding: 0 140px;
  background-color: #fff;
  &-send {
    display: flex;
    justify-content: center;
    align-items: center;
    .icon::before {
      content: '*';
      color: #f56c6c;
    }
  }
  &-main {
    padding: 70px 0;
    .check {
      padding-left: 85px;
    }
  }
}
</style>
