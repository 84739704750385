var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "myship" },
    [
      _vm.info.orderInfo.tprocAudit.approveStatus == "0" ||
      _vm.info.orderInfo.tprocAudit.approveStatus == "3"
        ? _c(
            "div",
            [
              _c(
                "div",
                { staticClass: "myship-send" },
                [
                  _c("span", { staticClass: "icon" }, [
                    _vm._v("上传发货凭证："),
                  ]),
                  _c(
                    "el-upload",
                    {
                      staticClass: "up-btn",
                      attrs: {
                        action: _vm.uploadPath,
                        "on-success": _vm.success,
                        "on-remove": _vm.remove,
                        accept: ".JPG,.PNG,.PDF,.ZIP",
                        multiple: "",
                      },
                    },
                    [
                      _c("el-button", { attrs: { type: "primary" } }, [
                        _vm._v("选择文件"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "p",
                { staticStyle: { "text-align": "center", margin: "20px 0" } },
                [
                  _vm._v(
                    "温馨提示：可上传JPG,PNG,PDF格式（最大能上传10M）、ZIP压缩文件（最大能上传10M）"
                  ),
                ]
              ),
              _c(
                "el-form",
                {
                  ref: "ruleForm",
                  staticClass: "demo-ruleForm",
                  attrs: {
                    model: _vm.ruleForm,
                    "status-icon": "",
                    rules: _vm.rules,
                    "label-width": "100px",
                  },
                },
                [
                  _c(
                    "el-row",
                    {
                      staticClass: "row-bg",
                      attrs: { type: "flex", justify: "space-between" },
                    },
                    [
                      _c(
                        "el-col",
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "物流公司", prop: "shipChannel" },
                            },
                            [
                              _c("el-input", {
                                model: {
                                  value: _vm.ruleForm.shipChannel,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.ruleForm, "shipChannel", $$v)
                                  },
                                  expression: "ruleForm.shipChannel",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "物流号", prop: "shipSn" } },
                            [
                              _c("el-input", {
                                model: {
                                  value: _vm.ruleForm.shipSn,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.ruleForm, "shipSn", $$v)
                                  },
                                  expression: "ruleForm.shipSn",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _c(
            "div",
            { staticClass: "myship-main" },
            [
              _c(
                "el-form",
                {
                  ref: "ruleForm",
                  staticClass: "demo-ruleForm",
                  attrs: {
                    model: _vm.ruleForm,
                    "status-icon": "",
                    rules: _vm.rules,
                    "label-width": "100px",
                  },
                },
                [
                  _c(
                    "el-row",
                    {
                      staticClass: "row-bg",
                      attrs: { gutter: 24, type: "flex" },
                    },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 16 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "物流公司", prop: "shipChannel" },
                            },
                            [
                              _c("el-input", {
                                attrs: { disabled: "" },
                                model: {
                                  value: _vm.fillingShipChannel,
                                  callback: function ($$v) {
                                    _vm.fillingShipChannel = $$v
                                  },
                                  expression: "fillingShipChannel",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 16 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "物流号", prop: "shipSn" } },
                            [
                              _c("el-input", {
                                attrs: { disabled: "" },
                                model: {
                                  value: _vm.fillingShipSn,
                                  callback: function ($$v) {
                                    _vm.fillingShipSn = $$v
                                  },
                                  expression: "fillingShipSn",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        {
                          staticStyle: { "text-align": "right" },
                          attrs: { span: 8 },
                        },
                        [
                          _c("span", [_vm._v("发货凭证：")]),
                          _c(
                            "el-button",
                            {
                              attrs: { type: "primary" },
                              on: {
                                click: function ($event) {
                                  _vm.$refs.documents.dialogVisible = true
                                },
                              },
                            },
                            [_vm._v("查看文件")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
      _c("dialog-browse-documents", { ref: "documents" }),
      _vm.info.orderInfo.orderProcess == "KH" &&
      _vm.info.orderInfo.orderStatus == "DFH"
        ? _c("Invoicing", {
            ref: "Invoicing",
            attrs: { KHData: _vm.KHData, info: _vm.info },
            on: { getInvoicingData: _vm.getInvoicingData },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }