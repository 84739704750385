<template>
  <div class="upload" v-if="detailInfo.orderInfo.tprocAudit">
    <!-- 合同签署 -->
    <Signing ref="IgnFor" :info="detailInfo" @getIgnForData="getIgnForData" v-if="isShowSection('signing')" />
    <!-- 签收 -->
    <IgnFor ref="IgnFor" :info="detailInfo" @getIgnForData="getIgnForData" v-if="isShowSection('ignFor')" />
    <!-- 发货 -->
    <Ship ref="Ship" :info="detailInfo" @submitOpr="submitOpr" @shipSuccess="shipSuccess" v-if="isShowSection('ship')" />
    <!-- 支付凭证信息 -->
    <BuyerPay ref="BuyerPay" :info="detailInfo" @getPayData="getPayData" v-if="isShowSection('pay')" />
    <!-- 上传发票部分 -->
    <Invoicing ref="Invoicing" :info="detailInfo" @getInvoicingData="getInvoicingData" v-if="isShowSection('Invoicing')" />
    <div v-if="(detailInfo.orderInfo.tprocAudit.approveStatus == '0' || detailInfo.orderInfo.tprocAudit.approveStatus == '3') && isShowBtn && $route.query.isHandleShow">
      <div class="upload-line"></div>
      <div class="btn">
        <el-button width="130" type="primary" @click="submitOpr">提交</el-button>
      </div>
    </div>
  </div>
</template>
<script>
import {permission} from '@/modules/mixin/permission-mixin.js';
import {uploadPath, oprOrderStatus} from '@/modules/User/api.js';
import Signing from '@/modules/User/components/contract/detail.vue';
import BuyerPay from '@/modules/User/components/order/operate/buyer-pay.vue';
import Ship from '@/modules/User/components/order/operate/ship.vue';
import IgnFor from '@/modules/User/components/order/operate/ignFor.vue';
import Invoicing from '@/modules/User/components/order/operate/invoicing.vue';
export default {
  data() {
    return {
      shipFlag: false,
      payFlag: false,
      invoicinFlag: false,
      uploadPath,
      // 供应商发货相关数据
      param: {
        fileUrls: [],
        fileNames: [],
        shipChannel: '', //物流公司
        shipSn: '' //物流号
      },
      // 买家签收相关数据
      ignFor: {
        flag: '',
        fileUrls: [],
        fileNames: []
      },
      payData: {}, //用户支付的输入数据
      invoicinData: {}, //上传发票数据
      fileUrls: [],
      fileNames: []
    };
  },
  components: {
    Signing,
    BuyerPay,
    Ship,
    Invoicing,
    IgnFor
  },
  props: {
    detailInfo: {
      type: Object
    }
  },
  computed: {
    isShowBtn() {
      let flag = false;
      if (this.$route.query.customer == 'sellder') {
        ['DFH', 'DKP'].includes(this.detailInfo.orderInfo.orderStatus) ? (flag = true) : '';
      } else {
        ['DSH', 'DZF'].includes(this.detailInfo.orderInfo.orderStatus) ? (flag = true) : '';
      }
      return flag;
    }
  },
  mixins: [permission],
  methods: {
    getInvoicingData(data) {
      this.invoicinData = data;
      this.invoicinFlag = data.flag;
      this.$refs.Invoicing.flag = data.flag;
      // 再次调用提交方法，否则上传发票的确认弹出框不会生效
      if (!data.isSame) this.submitOpr();
    },
    getIgnForData(data) {
      this.ignFor.flag = data.flag;
      this.ignFor.fileUrls = data.fileUrls;
      this.ignFor.fileNames = data.fileNames;
    },
    shipSuccess(data) {
      if (data.invoicingData.hasOwnProperty('isSame')) {
        this.invoicinData = data.invoicingData;
        console.log(this.invoicinData, 444);
      }
      this.shipFlag = data.flag;
      this.param.shipSn = data.shipSn;
      this.param.shipChannel = data.shipChannel;
      this.param.fileUrls = data.fileUrls;
      this.param.fileNames = data.fileNames;
    },
    getPayData(data) {
      this.payFlag = data.flag;
      this.payData = data;
    },
    submitOpr() {
      if (!this.isPermission(this.detailInfo.orderInfo, this.$route.query.customer)) {
        this.$message({
          message: '当前账号无权限',
          type: 'warning',
          center: true
        });
        return;
      }
      let myObj = {};
      let myFilterArr = [this.isShowSection('ship'), this.isShowSection('pay'), this.isShowSection('ignFor'), this.isShowSection('Invoicing')];
      myObj.status = this.getOprtStatus();
      // 判断签收凭证是否填写
      if (this.isShowSection('ignFor')) {
        this.$refs.IgnFor.submitForm();
        if (!this.ignFor.flag) return;
        this.fileUrls = this.ignFor.fileUrls;
        this.fileNames = this.ignFor.fileNames;
      }
      // 判断物流信息是否填写
      if (this.isShowSection('ship')) {
        this.$refs.Ship.submitForm();
        console.log(this.shipFlag);
        if (!this.shipFlag) return;
        if (this.invoicinData.hasOwnProperty('isSame')) {
          // 如果有上传发票的话
          myObj.listTInvoiceInfo = this.invoicinData.data; //上传发票的回调参数
          // 如果有发票上传和其他操作共存的情况
          myObj.fileUrls2 = this.invoicinData.tableFileUrls;
          myObj.fileNames2 = this.invoicinData.tableFileNames;
        }
        this.fileUrls = this.param.fileUrls;
        this.fileNames = this.param.fileNames;
        myObj.shipSn = this.param.shipSn; //物流号
        myObj.shipChannel = this.param.shipChannel; //物流公司
      }
      // 判断支付信息是否填写
      if (this.isShowSection('pay')) {
        this.$refs.BuyerPay.emitPayData();
        if (!this.payFlag) return;
        this.fileUrls = this.payData.fileUrls;
        this.fileNames = this.payData.fileNames;
        myObj.noteInfo = this.payData.data;
        myObj.payMode = this.detailInfo.orderInfo.isPayStages ? this.detailInfo.payDeliverRateList[Number(this.detailInfo.orderInfo.currentTerm - 1)].payMode : this.detailInfo.orderInfo.payMode;
        // myObj.payMode = this.payData.data.payMode;
      }

      // 判断上传发票信息是否正确
      if (this.isShowSection('Invoicing')) {
        this.$refs.Invoicing.emitInvoicingData();
        if (!this.invoicinFlag) return;
        myObj.listTInvoiceInfo = this.invoicinData.data; //上传发票的回调参数
        // 如果有发票上传和其他操作共存的情况
        if ((this.isShowSection('ship') || this.isShowSection('pay') || this.isShowSection('ignFor')) && this.isShowSection('Invoicing')) {
          myObj.fileUrls2 = this.invoicinData.tableFileUrls;
          myObj.fileNames2 = this.invoicinData.tableFileNames;
        }
        let myFilter = myFilterArr.filter(item => {
          return item == true;
        });
        // 如果只有上传发票(能进到这里就说明要显示发票上传，过滤完之后只有一个true就说明页面只显示上传发票)
        if (myFilter.length == 1) {
          this.fileUrls = this.invoicinData.tableFileUrls;
          this.fileNames = this.invoicinData.tableFileNames;
        }
      }
      // 判断文件是否上传
      if (this.isShowSection('ship') || this.isShowSection('pay') || this.isShowSection('ignFor') || this.isShowSection('Invoicing')) {
        if (!this.fileUrls.length) {
          this.$message.warning('请上传文件');
          return;
        }
      }
      myObj.fileUrls = this.fileUrls;
      myObj.fileNames = this.fileNames;
      myObj.record = this.detailInfo.orderInfo.currentTerm;
      myObj.orderSn = this.detailInfo.orderInfo.orderSn ? this.detailInfo.orderInfo.orderSn : '';
      myObj.orderId = this.detailInfo.orderInfo.id ? this.detailInfo.orderInfo.id : '';
      myObj.sellUser = this.detailInfo.orderInfo.consignee ? this.detailInfo.orderInfo.consignee : '';
      myObj.sellTel = this.detailInfo.orderInfo.mobile ? this.detailInfo.orderInfo.mobile : '';
      myObj.sellAddress = this.detailInfo.orderInfo.address ? this.detailInfo.orderInfo.address : '';
      myObj.invoiceAmt = this.detailInfo.orderInfo.orderPrice ? this.detailInfo.orderInfo.orderPrice : '';
      myObj.buyUser = this.detailInfo.orderInfo.userName ? this.detailInfo.orderInfo.userName : '';
      myObj.buyTel = this.detailInfo.orderInfo.phone ? this.detailInfo.orderInfo.phone : '';
      myObj.buyAddress = this.detailInfo.orderInfo.buyAddress ? this.detailInfo.orderInfo.buyAddress : '';
      oprOrderStatus(myObj).then(res => {
        if (res.errno === 0) {
          this.$notify.success({
            title: '成功',
            message: '提交成功'
          });
          this.param.fileUrl = '';
          this.param.fileName = '';
          this.resetFlag();
          this.$emit('upSuccess');
        }
      });
    },
    // 初始化各种flag
    resetFlag() {
      this.ignFor.flag = '';
      this.shipFlag = '';
      this.payFlag = '';
      this.invoicinFlag = '';
      if (this.$refs.Invoicing) this.$refs.Invoicing.flag = false;
    },
    getOprtStatus() {
      if (this.detailInfo.orderInfo.orderStatus === 'DFH') {
        if (this.$route.query.customer === 'sellder') {
          return 'FH';
        } else if (this.$route.query.customer === 'buyder') {
          return 'QX';
        }
      } else if (this.detailInfo.orderInfo.orderStatus === 'DSH') {
        if (this.$route.query.customer === 'buyder') {
          return 'SH';
        }
      } else if (this.detailInfo.orderInfo.orderStatus === 'DKP') {
        if (this.$route.query.customer === 'sellder') {
          return 'KP';
        }
      } else if (this.detailInfo.orderInfo.orderStatus === 'DZF') {
        if (this.$route.query.customer === 'buyder') {
          return 'ZF';
        }
      } else if (this.detailInfo.orderInfo.orderStatus === 'ZFC') {
        if (this.$route.query.customer === 'sellder') {
          return 'SK';
        }
      } else if (this.detailInfo.orderInfo.orderStatus === 'QXC') {
        if (this.$route.query.customer === 'sellder') {
          return 'QXS';
        }
      }
    },
    // 根据状态判断是否显示
    isShowSection(type) {
      let flag = false;
      // 合同签署模块的显示控制
      if ((this.detailInfo.orderInfo.orderStatus === 'DFWQ' || this.detailInfo.orderInfo.orderStatus === 'DQS') && type === 'signing') flag = true;
      // 操作相关模块的显示控制
      if (this.$route.query.customer === 'sellder' && (type === 'ship' || type === 'Invoicing')) {
        this.detailInfo.orderInfo.orderStatus === 'DFH' && type === 'ship' ? (flag = true) : '';
        this.detailInfo.orderInfo.orderStatus === 'DKP' && type === 'Invoicing' ? (flag = true) : '';
        // this.detailInfo.orderInfo.orderProcess === 'KH' && this.detailInfo.orderInfo.orderStatus === 'DFH' && type === 'Invoicing' ? (flag = true) : '';
      }
      if (this.$route.query.customer === 'buyder' && (type === 'ignFor' || type === 'pay')) {
        this.detailInfo.orderInfo.orderStatus === 'DSH' && type === 'ignFor' ? (flag = true) : '';
        this.detailInfo.orderInfo.orderStatus === 'DZF' && type === 'pay' ? (flag = true) : '';
      }
      return flag;
    },
    // 不是发票上传的文件上传成功时
    otherSuccess(response, file, fileList) {
      this.fileUrls.push(response.data.url);
      this.fileNames.push(file.name);
      this.$message.success('上传成功');
    },
    otherRemove(file) {
      this.fileUrls = this.fileUrls.filter(item => {
        return item !== file.response.data.url;
      });
      this.fileNames = this.fileNames.filter(item => {
        return item !== file.name;
      });
    }
  }
};
</script>
<style lang="less" scoped>
.upload {
  padding: 50px 50px 35px;
  background-color: #fff;
  &-up {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 50px;
    span::before {
      content: '*';
      color: red;
    }

    .up-btn /deep/ .el-button--primary {
      width: 101px;
      height: 34px;
      line-height: 10px;
    }

    .title {
      margin-left: 30px;
      color: #66b1ff;
      cursor: pointer;
    }
  }

  &-line {
    margin: 30px 0 50px 0;
    border-top: 1px dashed #bbb;
  }

  .btn {
    & /deep/ .el-button--primary {
      width: 130px;
    }
    text-align: center;
  }
}
</style>
