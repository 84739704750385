var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticStyle: { padding: "50px 50px 25px", "background-color": "#fff" } },
    [
      _vm.info.orderInfo.tprocAudit.approveStatus == "0" ||
      _vm.info.orderInfo.tprocAudit.approveStatus == "3"
        ? _c("div", { staticClass: "ignFor" }, [
            _c("div", { staticClass: "ignFor-up" }, [
              _c(
                "div",
                {
                  staticClass: "flex",
                  staticStyle: { "align-items": "center" },
                },
                [
                  _c("span", { staticClass: "up-file" }, [
                    _vm._v("上传签收凭证："),
                  ]),
                  _c(
                    "el-upload",
                    {
                      staticClass: "up-btn",
                      attrs: {
                        action: _vm.uploadPath,
                        "on-success": _vm.success,
                        "on-remove": _vm.remove,
                        accept: ".JPG,.PNG,.PDF,.ZIP",
                        multiple: "",
                      },
                    },
                    [
                      _c("el-button", { attrs: { type: "primary" } }, [
                        _vm._v("选择文件"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "p",
                { staticStyle: { "text-align": "center", margin: "20px 0" } },
                [
                  _vm._v(
                    "温馨提示：可上传JPG,PNG,PDF格式（最大能上传10M）、ZIP压缩文件（最大能上传10M）"
                  ),
                ]
              ),
            ]),
          ])
        : _c("div", { staticClass: "filling" }, [
            _c("img", {
              attrs: { src: require("@/assets/order/success.png") },
            }),
            _c("p", { staticStyle: { "margin-top": "15px" } }, [
              _vm._v("买家已提交"),
            ]),
            _c("p", { staticStyle: { "margin-bottom": "15px" } }, [
              _vm._v("待审核"),
            ]),
            _c(
              "div",
              [
                _c("span", [_vm._v("签收凭证： ")]),
                _c(
                  "el-button",
                  {
                    attrs: { type: "primary" },
                    on: {
                      click: function ($event) {
                        _vm.$refs.documents.dialogVisible = true
                      },
                    },
                  },
                  [_vm._v("查看文件")]
                ),
              ],
              1
            ),
          ]),
      _c("dialog-browse-documents", { ref: "documents" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }