var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "upload" },
    [
      _c("div", [
        _vm.info.orderInfo.tprocAudit.approveStatus == "0" ||
        _vm.info.orderInfo.tprocAudit.approveStatus == "3"
          ? _c("div", [
              _c(
                "div",
                { staticClass: "upload-content" },
                [
                  _c(
                    "div",
                    {
                      staticClass: "upload-up",
                      staticStyle: { "margin-right": "10px" },
                    },
                    [
                      _c("span", [_vm._v("上传发票：")]),
                      _c(
                        "el-upload",
                        {
                          staticClass: "up-btn",
                          attrs: {
                            "show-file-list": false,
                            accept: ".JPG,.PNG,.PDF,.ZIP",
                            action: _vm.uploadPath,
                            "before-upload": _vm.beforeUpload,
                            "on-error": _vm.uploadError,
                            "on-success": _vm.handleSuccess,
                            multiple: "",
                          },
                        },
                        [
                          _c("el-button", { attrs: { type: "primary" } }, [
                            _vm._v("选择文件"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "upload-up" },
                    [
                      _c("span", [_vm._v("上传非发票：")]),
                      _c(
                        "el-upload",
                        {
                          staticClass: "up-btn",
                          attrs: {
                            "show-file-list": false,
                            accept: ".JPG,.PNG,.PDF",
                            action: _vm.uploadPath,
                            "before-upload": _vm.otherBeforeUpload,
                            "on-error": _vm.otherUploadError,
                            "on-success": _vm.otherSuccess,
                            multiple: "",
                          },
                        },
                        [
                          _c("el-button", { attrs: { type: "primary" } }, [
                            _vm._v("选择文件"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("el-tooltip", { attrs: { placement: "top" } }, [
                    _c("div", { attrs: { slot: "content" }, slot: "content" }, [
                      _c("img", {
                        staticStyle: { height: "450px" },
                        attrs: {
                          src: require("@/assets/image/bill.jpg"),
                          alt: "",
                        },
                      }),
                    ]),
                    _c("p", { staticClass: "title" }, [_vm._v("样例预览")]),
                  ]),
                ],
                1
              ),
              _c(
                "p",
                { staticStyle: { "text-align": "center", margin: "20px 0" } },
                [
                  _vm._v(
                    "温馨提示：可上传JPG,PNG,PDF格式（最大能上传10M）、ZIP压缩文件（最大能上传10M）"
                  ),
                ]
              ),
            ])
          : _vm._e(),
        _vm.tableData.length &&
        _vm.info.orderInfo.tprocAudit.approveStatus != "2"
          ? _c(
              "table",
              { staticClass: "upload-table" },
              [
                _vm._m(0),
                _vm._l(_vm.tableData, function (item, index) {
                  return _c("tr", { key: index }, [
                    _c("td", [
                      (item.purchaserName !== item.buyClientName ||
                        item.sellerName !== item.sellClientName) &&
                      item.invoiceNo
                        ? _c("img", {
                            attrs: { src: require("@/assets/image/false.png") },
                          })
                        : _c("img", {
                            attrs: { src: require("@/assets/image/true.png") },
                          }),
                    ]),
                    _c("td", [
                      _c("span", [_vm._v(_vm._s(item.invoiceSn || "-"))]),
                    ]),
                    _c("td", [
                      _c("span", [_vm._v(_vm._s(item.invoiceNo || "-"))]),
                    ]),
                    _c("td", [
                      _c("span", [
                        _vm._v(_vm._s(_vm._f("formatPrice")(item.invoiceAmt))),
                      ]),
                    ]),
                    _c("td", [
                      _c("span", [
                        _vm._v(
                          _vm._s(_vm._f("formatPrice")(item.notInvoiceAmt))
                        ),
                      ]),
                    ]),
                    _c("td", [
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            item.invoiceType == "0"
                              ? "专用发票"
                              : item.invoiceType == "1"
                              ? "普通发票"
                              : "额外文件"
                          )
                        ),
                      ]),
                    ]),
                    _c("td", [
                      _vm._v(
                        " " + _vm._s(_vm.getFormatDate(item.invoiceTime)) + " "
                      ),
                    ]),
                    _c("td", [
                      _c("p", [
                        _c(
                          "span",
                          {
                            staticStyle: {
                              color: "#4ba5ff",
                              cursor: "pointer",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.showDialogInvoiceComparison(item)
                              },
                            },
                          },
                          [_vm._v("查看发票")]
                        ),
                        _vm.info.orderInfo.tprocAudit.approveStatus != "1"
                          ? _c(
                              "span",
                              {
                                staticStyle: {
                                  color: "#4ba5ff",
                                  cursor: "pointer",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.delItem(index, item)
                                  },
                                },
                              },
                              [_vm._v("删除")]
                            )
                          : _vm._e(),
                      ]),
                    ]),
                  ])
                }),
              ],
              2
            )
          : _vm._e(),
        _vm.tableData.length &&
        _vm.info.orderInfo.tprocAudit.approveStatus != "2"
          ? _c("div", { staticClass: "upload-total" }, [
              _c("span", [
                _vm._v("发票总数：" + _vm._s(_vm.invoiceData.length)),
              ]),
              _c("span", [
                _vm._v(
                  "含税金额（元）：" +
                    _vm._s(_vm._f("formatPrice")(_vm.getAllinvoiceAmtString))
                ),
              ]),
              _c("span", [
                _vm._v(
                  "不含税金额（元）：" +
                    _vm._s(_vm._f("formatPrice")(_vm.getAllnotInvoiceAmtString))
                ),
              ]),
              _c("span", [
                _vm._v(
                  "合同总金额：" +
                    _vm._s(_vm._f("formatPrice")(_vm.info.orderInfo.orderPrice))
                ),
              ]),
            ])
          : _vm._e(),
      ]),
      _c("dialog-invoice-comparison", {
        ref: "invoiceComparison",
        attrs: {
          goodsPrice: _vm._f("formatPrice")(_vm.info.orderInfo.orderPrice),
        },
        on: { getTableData: _vm.getTableData },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("th", { attrs: { width: "60px" } }, [_vm._v("系统校验")]),
      _c("th", { attrs: { width: "110px" } }, [_vm._v("发票代码")]),
      _c("th", { attrs: { width: "80px" } }, [_vm._v("发票号码")]),
      _c("th", [_vm._v("含税金额（元）")]),
      _c("th", [_vm._v("不含税金额（元）")]),
      _c("th", { attrs: { width: "70px" } }, [_vm._v("发票类型")]),
      _c("th", [_vm._v("开票时间")]),
      _c("th", [_vm._v("操作")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }