var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.detailInfo.orderInfo.tprocAudit
    ? _c(
        "div",
        { staticClass: "upload" },
        [
          _vm.isShowSection("signing")
            ? _c("Signing", {
                ref: "IgnFor",
                attrs: { info: _vm.detailInfo },
                on: { getIgnForData: _vm.getIgnForData },
              })
            : _vm._e(),
          _vm.isShowSection("ignFor")
            ? _c("IgnFor", {
                ref: "IgnFor",
                attrs: { info: _vm.detailInfo },
                on: { getIgnForData: _vm.getIgnForData },
              })
            : _vm._e(),
          _vm.isShowSection("ship")
            ? _c("Ship", {
                ref: "Ship",
                attrs: { info: _vm.detailInfo },
                on: { submitOpr: _vm.submitOpr, shipSuccess: _vm.shipSuccess },
              })
            : _vm._e(),
          _vm.isShowSection("pay")
            ? _c("BuyerPay", {
                ref: "BuyerPay",
                attrs: { info: _vm.detailInfo },
                on: { getPayData: _vm.getPayData },
              })
            : _vm._e(),
          _vm.isShowSection("Invoicing")
            ? _c("Invoicing", {
                ref: "Invoicing",
                attrs: { info: _vm.detailInfo },
                on: { getInvoicingData: _vm.getInvoicingData },
              })
            : _vm._e(),
          (_vm.detailInfo.orderInfo.tprocAudit.approveStatus == "0" ||
            _vm.detailInfo.orderInfo.tprocAudit.approveStatus == "3") &&
          _vm.isShowBtn &&
          _vm.$route.query.isHandleShow
            ? _c("div", [
                _c("div", { staticClass: "upload-line" }),
                _c(
                  "div",
                  { staticClass: "btn" },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { width: "130", type: "primary" },
                        on: { click: _vm.submitOpr },
                      },
                      [_vm._v("提交")]
                    ),
                  ],
                  1
                ),
              ])
            : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }