var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "content" },
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: "发票对比",
            visible: _vm.dialogVisible,
            width: "1200px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c("div", { staticClass: "invoice" }, [
            _c("div", [
              _c("div", { ref: "embed" }, [
                _c("embed", {
                  attrs: {
                    height: "540px",
                    width: "700px",
                    src: _vm.IdentifyData.fileUrl,
                  },
                }),
              ]),
              (_vm.IdentifyData.purchaserName !=
                _vm.IdentifyData.buyClientName ||
                _vm.IdentifyData.sellerName !=
                  _vm.IdentifyData.sellClientName) &&
              _vm.IdentifyData.invoiceNo
                ? _c("div", { staticClass: "invoice-hint" }, [
                    _vm._v(
                      " 温馨提示：请核对识别信息与发票信息是否一致，如不一致，请修改后再确认 "
                    ),
                  ])
                : _vm._e(),
            ]),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.IdentifyData.invoiceNo,
                    expression: "IdentifyData.invoiceNo",
                  },
                ],
                staticClass: "invoice-right",
              },
              [
                _c("p", { staticClass: "right-msg" }, [_vm._v("识别信息")]),
                _c(
                  "el-form",
                  {
                    ref: "Identify",
                    staticClass: "right-form",
                    attrs: {
                      "label-position": "top",
                      rules: _vm.rules,
                      model: _vm.IdentifyData,
                      "label-width": "80px",
                    },
                  },
                  [
                    _c(
                      "el-row",
                      { attrs: { type: "flex", justify: "space-between" } },
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 11 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: { label: "发票代码", prop: "invoiceSn" },
                              },
                              [
                                _c("el-input", {
                                  model: {
                                    value: _vm.IdentifyData.invoiceSn,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.IdentifyData,
                                        "invoiceSn",
                                        $$v
                                      )
                                    },
                                    expression: "IdentifyData.invoiceSn",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 11 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: { label: "发票号码", prop: "invoiceNo" },
                              },
                              [
                                _c("el-input", {
                                  model: {
                                    value: _vm.IdentifyData.invoiceNo,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.IdentifyData,
                                        "invoiceNo",
                                        $$v
                                      )
                                    },
                                    expression: "IdentifyData.invoiceNo",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      { attrs: { type: "flex", justify: "space-between" } },
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 11 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "开票日期",
                                  prop: "invoiceTime",
                                },
                              },
                              [
                                _c("el-date-picker", {
                                  attrs: {
                                    type: "date",
                                    placeholder: "选择日期",
                                  },
                                  model: {
                                    value: _vm.IdentifyData.invoiceTime,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.IdentifyData,
                                        "invoiceTime",
                                        $$v
                                      )
                                    },
                                    expression: "IdentifyData.invoiceTime",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 11 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "发票类型",
                                  prop: "invoiceType",
                                },
                              },
                              [
                                _c(
                                  "el-select",
                                  {
                                    attrs: { placeholder: "请选择" },
                                    model: {
                                      value: _vm.IdentifyData.invoiceType,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.IdentifyData,
                                          "invoiceType",
                                          $$v
                                        )
                                      },
                                      expression: "IdentifyData.invoiceType",
                                    },
                                  },
                                  _vm._l(_vm.options, function (item) {
                                    return _c("el-option", {
                                      key: item.value,
                                      attrs: {
                                        label: item.name,
                                        value: item.value,
                                      },
                                    })
                                  }),
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm.IdentifyData.invoiceType === "1"
                      ? _c(
                          "el-row",
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "校验码",
                                  prop: "invoiceSecurityCode",
                                },
                              },
                              [
                                _c("el-input", {
                                  model: {
                                    value: _vm.IdentifyData.invoiceSecurityCode,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.IdentifyData,
                                        "invoiceSecurityCode",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "IdentifyData.invoiceSecurityCode",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: { label: "买方名称", prop: "purchaserName" },
                          },
                          [
                            _c("el-input", {
                              model: {
                                value: _vm.IdentifyData.purchaserName,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.IdentifyData,
                                    "purchaserName",
                                    $$v
                                  )
                                },
                                expression: "IdentifyData.purchaserName",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "卖方名称", prop: "sellerName" } },
                          [
                            _c("el-input", {
                              model: {
                                value: _vm.IdentifyData.sellerName,
                                callback: function ($$v) {
                                  _vm.$set(_vm.IdentifyData, "sellerName", $$v)
                                },
                                expression: "IdentifyData.sellerName",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      { attrs: { type: "flex", justify: "space-between" } },
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 11 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "不含税金额（元）",
                                  prop: "notInvoiceAmtString",
                                },
                              },
                              [
                                _c("el-input", {
                                  on: {
                                    blur: function ($event) {
                                      return _vm.completion(
                                        "notInvoiceAmtString"
                                      )
                                    },
                                  },
                                  model: {
                                    value: _vm.IdentifyData.notInvoiceAmtString,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.IdentifyData,
                                        "notInvoiceAmtString",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "IdentifyData.notInvoiceAmtString",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 11 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "含税金额（元）",
                                  prop: "invoiceAmtString",
                                },
                              },
                              [
                                _c("el-input", {
                                  on: {
                                    blur: function ($event) {
                                      return _vm.completion("invoiceAmtString")
                                    },
                                  },
                                  model: {
                                    value: _vm.IdentifyData.invoiceAmtString,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.IdentifyData,
                                        "invoiceAmtString",
                                        $$v
                                      )
                                    },
                                    expression: "IdentifyData.invoiceAmtString",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "p",
                      {
                        staticClass: "right-msg",
                        staticStyle: { "margin-top": "15px" },
                      },
                      [_vm._v("订单信息")]
                    ),
                    _c(
                      "el-row",
                      [
                        _c("el-form-item", { attrs: { label: "买家名称" } }, [
                          _c("p", [
                            _vm._v(_vm._s(_vm.IdentifyData.buyClientName)),
                          ]),
                        ]),
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      [
                        _c("el-form-item", { attrs: { label: "卖家名称" } }, [
                          _c("p", [
                            _vm._v(_vm._s(_vm.IdentifyData.sellClientName)),
                          ]),
                        ]),
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      [
                        _c("el-form-item", { attrs: { label: "订单金额" } }, [
                          _c("p", [_vm._v(_vm._s(_vm.goodsPrice))]),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.confirm()
                    },
                  },
                },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }