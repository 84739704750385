<template>
  <div class="content">
    <el-dialog title="发票对比" :visible.sync="dialogVisible" width="1200px">
      <div class="invoice">
        <div>
          <div ref="embed">
            <embed height="540px" width="700px" :src="IdentifyData.fileUrl" />
          </div>
          <div v-if="(IdentifyData.purchaserName != IdentifyData.buyClientName || IdentifyData.sellerName != IdentifyData.sellClientName) && IdentifyData.invoiceNo" class="invoice-hint">
            温馨提示：请核对识别信息与发票信息是否一致，如不一致，请修改后再确认
          </div>
        </div>
        <div v-show="IdentifyData.invoiceNo" class="invoice-right">
          <p class="right-msg">识别信息</p>
          <el-form class="right-form" label-position="top" :rules="rules" :model="IdentifyData" ref="Identify" label-width="80px">
            <el-row type="flex" justify="space-between">
              <el-col :span="11">
                <el-form-item label="发票代码" prop="invoiceSn">
                  <el-input v-model="IdentifyData.invoiceSn"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="11">
                <el-form-item label="发票号码" prop="invoiceNo">
                  <el-input v-model="IdentifyData.invoiceNo"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row type="flex" justify="space-between">
              <el-col :span="11">
                <el-form-item label="开票日期" prop="invoiceTime">
                  <el-date-picker v-model="IdentifyData.invoiceTime" type="date" placeholder="选择日期"> </el-date-picker>
                </el-form-item>
              </el-col>
              <el-col :span="11">
                <el-form-item label="发票类型" prop="invoiceType">
                  <el-select v-model="IdentifyData.invoiceType" placeholder="请选择">
                    <el-option v-for="item in options" :key="item.value" :label="item.name" :value="item.value"> </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row v-if="IdentifyData.invoiceType === '1'">
              <el-form-item label="校验码" prop="invoiceSecurityCode">
                <el-input v-model="IdentifyData.invoiceSecurityCode"></el-input>
              </el-form-item>
            </el-row>
            <el-row>
              <el-form-item label="买方名称" prop="purchaserName">
                <el-input v-model="IdentifyData.purchaserName"></el-input>
              </el-form-item>
            </el-row>
            <el-row>
              <el-form-item label="卖方名称" prop="sellerName">
                <el-input v-model="IdentifyData.sellerName"></el-input>
              </el-form-item>
            </el-row>
            <el-row type="flex" justify="space-between">
              <el-col :span="11">
                <el-form-item label="不含税金额（元）" prop="notInvoiceAmtString">
                  <el-input @blur="completion('notInvoiceAmtString')" v-model="IdentifyData.notInvoiceAmtString"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="11">
                <el-form-item label="含税金额（元）" prop="invoiceAmtString">
                  <el-input @blur="completion('invoiceAmtString')" v-model="IdentifyData.invoiceAmtString"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <p class="right-msg" style="margin-top: 15px;">订单信息</p>
            <el-row>
              <el-form-item label="买家名称">
                <p>{{ IdentifyData.buyClientName }}</p>
              </el-form-item>
            </el-row>
            <el-row>
              <el-form-item label="卖家名称">
                <p>{{ IdentifyData.sellClientName }}</p>
              </el-form-item>
            </el-row>
            <el-row>
              <el-form-item label="订单金额">
                <p>{{ goodsPrice }}</p>
              </el-form-item>
            </el-row>
          </el-form>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="confirm()">确定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    var validateSn = (rule, value, callback) => {
      if (value === '') {
        return callback(new Error('请输入内容'));
      } else if (/[^\d]/g.test(value)) {
        return callback(new Error('请输入数字'));
      }
      return callback();
    };
    var validateNum = (rule, value, callback) => {
      if (value === '') {
        return callback(new Error('请输入内容'));
      } else if (!/^(([1-9][0-9]*)|(([0]\.\d{1,2}|[1-9][0-9]*\.\d{1,2})))$/.test(value)) {
        return callback(new Error('小数点后只能有2位'));
      }
      return callback();
    };
    return {
      IdentifyData: {},
      rules: {
        invoiceSn: [{validator: validateSn, trigger: 'blur'}],
        invoiceNo: [{validator: validateSn, trigger: 'blur'}],
        invoiceTime: [{required: true, message: '请选择日期', trigger: 'change'}],
        invoiceType: [{required: true, message: '请选择发票类型', trigger: 'change'}],
        invoiceSecurityCode: [{validator: validateSn, trigger: 'blur'}],
        purchaserName: [{required: true, message: '请输入买方名称', trigger: 'blur'}],
        sellerName: [{required: true, message: '请输入卖方名称', trigger: 'blur'}],
        notInvoiceAmtString: [{validator: validateNum, trigger: 'blur'}],
        invoiceAmtString: [{validator: validateNum, trigger: 'blur'}]
      },
      options: [
        {name: '专用发票', value: '0'},
        {name: '普通发票', value: '1'}
      ],
      dialogVisible: false,
      imgUrl: ''
    };
  },

  props: {
    goodsPrice: {
      type: String
    }
  },
  watch: {
    dialogVisible: function(val) {
      if (val) {
        let dom = `<embed height="540px" width="700px" src="${this.IdentifyData.fileUrl}">`;
        this.$nextTick(() => {
          this.$refs.embed.innerHTML = '';
          this.$refs.embed.innerHTML = dom;
        });
      }
    }
  },
  methods: {
    completion(type) {
      let myindex = this.IdentifyData[type].indexOf('.');
      if (myindex < 0) {
        this.IdentifyData[type] = this.IdentifyData[type] + '.00';
      }
      console.log(this.IdentifyData[type].slice(myindex + 1));
      if (myindex > 0 && this.IdentifyData[type].slice(myindex + 1).length == 1) {
        this.IdentifyData[type] = this.IdentifyData[type] + '0';
      }
    },
    confirm() {
      this.$refs.Identify.validate(valid => {
        if (!this.IdentifyData.invoiceNo) {
          this.dialogVisible = false;
        }
        if (valid) {
          this.IdentifyData.invoiceTime = this.$moment(this.IdentifyData.invoiceTime).format('YYYY-MM-DD');
          this.$emit('getTableData', this.IdentifyData);
          this.dialogVisible = false;
        }
      });
    }
  }
};
</script>
<style lang="less" scoped>
.content /deep/ .el-dialog__header {
  padding-bottom: 0;
}

.content /deep/ .el-dialog__footer {
  text-align: center;
  cursor: pointer;
}

.content /deep/ .el-form-item__label {
  height: 30px;
  color: #9c5324;
}

.content /deep/ .el-form-item {
  margin-bottom: 5px;
}

.right-form /deep/ .el-input__inner {
  height: 30px;
  font-size: 16px;
}

.right-form /deep/ .el-row {
  padding: 0 20px;
}

.right-form /deep/ .el-date-editor.el-input {
  width: 187.91px;
}

.right-form /deep/ .el-form-item.is-required:not(.is-no-asterisk) > .el-form-item__label:before {
  content: '';
}

.right-form /deep/ .el-form-item__error {
  padding: 0;
}
.invoice {
  display: flex;
  justify-content: space-between;
  img {
    height: 540px;
  }
  ul {
    padding: 0 20px;
  }
  li {
    height: 45px;
    margin: 0 60px 30px 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    &:nth-last-child(1) {
      margin: 0;
    }
    div {
      color: #9c5324;
    }
  }
  &-hint {
    height: 30px;
    line-height: 30px;
    font-size: 14px;
    color: #fff;
    margin-top: -4px;
    background-color: #fe7e28;
    padding-left: 25px;
  }
  &-right {
    width: 450px;
    .right-msg {
      padding-left: 15px;
      height: 34px;
      line-height: 34px;
      background-color: #f6f6f6;
    }
  }
  &-title {
    margin-bottom: 20px;
    padding: 0 20px;
    height: 34px;
    line-height: 34px;
    background-color: #f6f6f6;
  }
  &-date {
    overflow: hidden;
    li {
      float: left;
    }
    .left {
      width: 170px;
      text-align: left;
    }
  }
}
</style>
