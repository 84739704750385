<template>
  <div class="pay">
    <div>
      <!-- 上传支付文件操作栏 -->
      <div v-if="!isDisabled">
        <div class="pay-up">
          <span>上传支付凭证：</span>
          <el-upload class="up-btn" accept=".JPG,.PNG,.PDF" :action="uploadPath" :on-remove="uploadremove" :on-success="handleSuccess" multiple>
            <el-button type="primary">选择文件</el-button>
          </el-upload>
          <el-tooltip placement="top" v-if="getPaymode">
            <div slot="content">
              <img style="height:450px" src="@/modules/User/image/huipiao.png" alt="" />
            </div>
            <p class="upload-title">样例预览</p>
          </el-tooltip>
        </div>
        <p v-if="getPaymode" style="text-align:center;margin: 20px 0;">可上传格式：JPG，PNG，PDF上传后自动识别显示</p>
        <p v-else style="text-align: center;margin: 20px 0;">可上传格式：JPG，PNG，PDF格式</p>
      </div>
      <!-- 查看支付凭证回显文件 -->
      <div class="pay-filling" v-if="isDisabled">
        <span>票据凭证：</span>
        <el-button type="primary" @click="showDialog">查看文件</el-button>
      </div>
      <el-form v-if="getPaymode" :model="noteInfo" :rules="rules" ref="payForm" :inline="true" class="pay-form">
        <el-row>
          <div class="pay-line">
            <p>收/付款账户信息</p>
            <span></span>
          </div>
        </el-row>
        <el-row>
          <el-col :span="11">
            <el-form-item class="form-item" label="付款人名称：" prop="payerName">
              <el-input :disabled="isDisabled" class="input-width" v-model="noteInfo.payerName" size="mini"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item class="form-item" label="收款人名称：" prop="payeeName">
              <el-input :disabled="isDisabled" class="input-width" v-model="noteInfo.payeeName" size="mini"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="11">
            <el-form-item label="付款人账号：" prop="payerAccount">
              <el-input :disabled="isDisabled" class="input-width" v-model="noteInfo.payerAccount" size="mini"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item label="收款人账号：" prop="payeeAccount">
              <el-input :disabled="isDisabled" class="input-width" v-model="noteInfo.payeeAccount" size="mini"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="11">
            <el-form-item label="付款人开户银行：" prop="payerBankName">
              <el-input :disabled="isDisabled" class="input-width" v-model="noteInfo.payerBankName" size="mini"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item label="收款人开户银行：" prop="payeeBankName">
              <el-input :disabled="isDisabled" class="input-width" v-model="noteInfo.payeeBankName" size="mini"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <div class="pay-line">
            <p style="width:65px">汇票信息</p>
            <span></span>
          </div>
        </el-row>
        <el-row>
          <el-col :span="11">
            <el-form-item label="票据金额(元)：" prop="notePrice">
              <el-input :disabled="isDisabled" class="input-width" v-model="noteInfo.notePrice" @copy.native.capture.prevent="" @cut.native.capture.prevent="" size="mini"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item label="票据号码：" prop="noteNumber">
              <el-input :disabled="isDisabled" class="input-width" v-model="noteInfo.noteNumber" @copy.native.capture.prevent="" @cut.native.capture.prevent="" size="mini"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row v-if="!isDisabled">
          <div class="pay-line">
            <p style="width:95px">确认汇票信息</p>
            <span></span>
          </div>
        </el-row>
        <el-row v-if="!isDisabled">
          <el-col :span="11">
            <el-form-item label="票据金额确认(元)：" prop="notePriceConfirm">
              <el-input class="input-width" v-model="noteInfo.notePriceConfirm" size="mini"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item label="票据号码确认：" prop="noteNumberConfirm">
              <el-input class="input-width" v-model="noteInfo.noteNumberConfirm" size="mini"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <!-- 查看文件弹窗 -->
    <dialog-browse-documents ref="documents" />
  </div>
</template>
<script>
import {formatDialogFile} from '@/modules/mixin/order-mixin.js';
import dialogBrowseDocuments from '@/modules/User/components/order/operate/dialog-browse-documents';
import {identifyNote, uploadPath, getZFEchoInformation} from '@/modules/User/api.js';
export default {
  data() {
    return {
      url: '',
      fileUrls: [],
      fileNames: [],
      uploadPath,
      noteInfo: {
        payMode: '',
        payerName: '', //付款人
        payeeName: '', //收款人
        payerAccount: '', //付款人账号
        payeeAccount: '', //收款人账号
        payerBankName: '', //付款人开户银行
        payeeBankName: '', //收款人开户银行
        notePrice: '', //票据金额
        noteNumber: '', //票据号码
        notePriceConfirm: '', //票据金额确认
        noteNumberConfirm: '' //票据号码确认
      },
      rules: {
        payerName: [{required: true, message: '请输入付款人名称', trigger: 'blur'}],
        payeeName: [{required: true, message: '请输入收款人名称', trigger: 'blur'}],
        payerAccount: [{required: true, message: '请输入付款人账号', trigger: 'blur'}],
        payeeAccount: [{required: true, message: '请输入收款人账号', trigger: 'blur'}],
        payerBankName: [{required: true, message: '请输入付款人开户银行', trigger: 'blur'}],
        payeeBankName: [{required: true, message: '请输入收款人开户银行', trigger: 'blur'}],
        notePrice: [{required: true, message: '请输入票据金额', trigger: 'blur'}],
        noteNumber: [{required: true, message: '请输入票据号码', trigger: 'blur'}],
        notePriceConfirm: [{required: true, message: '请输入票据金额', trigger: 'blur'}],
        noteNumberConfirm: [{required: true, message: '请输入票据号码', trigger: 'blur'}]
      }
    };
  },
  components: {
    dialogBrowseDocuments
  },
  mixins: [formatDialogFile],
  props: {
    info: {
      type: Object
    }
  },
  computed: {
    // true代表需要显示回显页面，false不显示
    isDisabled() {
      if (this.info.orderInfo.tprocAudit.approveStatus == '1') {
        return true;
      } else {
        return false;
      }
    },
    getPaymode() {
      let flag = false;
      if (this.info.orderInfo.isPayStages) {
        this.info.payDeliverRateList[Number(this.info.orderInfo.currentTerm) - 1].payMode == 'YHZZ' ? '' : (flag = true);
      } else {
        this.info.orderInfo.payMode.indexOf('CDHP') !== -1 ? (flag = true) : '';
      }
      return flag;
    }
  },
  methods: {
    resetData() {
      this.fileUrls = [];
      this.fileNames = [];
      this.noteInfo = {
        payMode: '',
        payerName: '', //付款人
        payeeName: '', //收款人
        payerAccount: '', //付款人账号
        payeeAccount: '', //收款人账号
        payerBankName: '', //付款人开户银行
        payeeBankName: '', //收款人开户银行
        notePrice: '', //票据金额
        noteNumber: '', //票据号码
        notePriceConfirm: '', //票据金额确认
        noteNumberConfirm: '' //票据号码确认
      };
    },
    showDialog() {
      this.$refs.documents.dialogVisible = true;
    },
    // 支付回显接口
    async getfillingData() {
      let params = {
        orderId: this.info.orderInfo.id,
        paymentStatus: this.info.orderInfo.payMode,
        status: 'ZF',
        record: this.info.orderInfo.currentTerm,
        approveStatus: this.info.orderInfo.tprocAudit.approveStatus
      };
      let res = await getZFEchoInformation(params);
      if (res.errno === 0) {
        if (res.data.noteInfo) {
          this.noteInfo.payerName = res.data.noteInfo.payerName;
          this.noteInfo.payeeName = res.data.noteInfo.payeeName;
          this.noteInfo.payerAccount = res.data.noteInfo.payerAccount;
          this.noteInfo.payeeAccount = res.data.noteInfo.payeeAccount;
          this.noteInfo.payerBankName = res.data.noteInfo.payerBankName;
          this.noteInfo.payeeBankName = res.data.noteInfo.payeeBankName;
          this.noteInfo.notePrice = res.data.noteInfo.notePrice;
          this.noteInfo.noteNumber = res.data.noteInfo.noteNumber;
        }
        this.$refs.documents.imgData = this.formatFile(res.data.torderRecord).arr;
        this.$refs.documents.total = this.formatFile(res.data.torderRecord).total;
      }
    },
    uploadremove(res) {
      this.fileUrls = this.fileUrls.filter(item => {
        return item !== res.response.data.url;
      });
      this.fileNames = this.fileNames.filter(item => {
        return item !== res.name;
      });
      if (res.response.data.url == this.url) {
        this.noteInfo = {
          payMode: '',
          payerName: '', //付款人
          payeeName: '', //收款人
          payerAccount: '', //付款人账号
          payeeAccount: '', //收款人账号
          payerBankName: '', //付款人开户银行
          payeeBankName: '', //收款人开户银行
          notePrice: '', //票据金额
          noteNumber: '', //票据号码
          notePriceConfirm: '', //票据金额确认
          noteNumberConfirm: '' //票据号码确认
        };
      }
    },
    async handleSuccess(resp, file) {
      this.fileUrls.push(resp.data.url);
      this.fileNames.push(file.name);
      let res = await identifyNote({fileUrl: resp.data.url, isBase64: true});
      if (res.errno == 0) {
        this.$message.success('上传成功');
        this.noteInfo.payerName = res.data.noteInfo.payerName;
        this.noteInfo.payeeName = res.data.noteInfo.payeeName;
        this.noteInfo.payerAccount = res.data.noteInfo.payerAccount;
        this.noteInfo.payeeAccount = res.data.noteInfo.payeeAccount;
        this.noteInfo.payerBankName = res.data.noteInfo.payerBankName;
        this.noteInfo.payeeBankName = res.data.noteInfo.payeeBankName;
        this.noteInfo.notePrice = res.data.noteInfo.notePrice;
        this.noteInfo.noteNumber = res.data.noteInfo.noteNumber;
        this.noteInfo.payMode = this.info.orderInfo.isPayStages ? this.info.payDeliverRateList[Number(this.info.orderInfo.currentTerm - 1)].payMode : this.info.orderInfo.payMode;
        this.url = resp.data.url;
      } else {
        this.fileUrls = this.fileUrls.filter(item => {
          return resp.data.url !== item;
        });
        this.fileNames = this.fileNames.filter(item => {
          return file.name !== item;
        });
      }
    },

    emitPayData() {
      if (this.getPaymode) {
        if (this.noteInfo.notePrice != this.noteInfo.notePriceConfirm || this.noteInfo.noteNumber != this.noteInfo.noteNumberConfirm) {
          this.$message.warning('请再次确认票据金额和票据号码');
          return;
        }
      }
      if (this.getPaymode) {
        // 如果是汇票情况
        this.$refs.payForm.validate(valid => {
          this.$emit('getPayData', {data: this.noteInfo, flag: valid, fileUrls: this.fileUrls, fileNames: this.fileNames});
        });
      } else {
        this.$emit('getPayData', {data: this.noteInfo, flag: true, fileUrls: this.fileUrls, fileNames: this.fileNames});
      }
    }
  }
};
</script>
<style lang="less" scoped>
.pay {
  & /deep/ .el-form-item {
    float: right;
  }
  &-form {
    padding: 0 20px;
  }
  &-up {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    span::before {
      content: '*';
      color: red;
    }

    .up-btn /deep/ .el-button--primary {
      width: 101px;
      height: 34px;
      line-height: 10px;
    }

    .upload-title {
      margin-left: 30px;
      color: #66b1ff;
      cursor: pointer;
    }
  }
  &-filling {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 40px;
  }
  .input-width {
    width: 250px;
  }
  &-line {
    display: flex;
    align-items: center;
    margin-bottom: 30px;
    p {
      width: 120px;
      color: #999999;
    }
    span {
      display: inline-block;
      width: 100%;
      border-top: 1px solid #bbb;
    }
  }
}
</style>
